import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 640 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M209.8 84.8C222.3 35 267.1 0 318.5 0h1.5c61.9 0 112 50.1 112 112v6.3c0 36.5-17.8 70.8-47.8 91.8l-8.3 5.8 232.3 161.3C628.1 391 640 413.7 640 438c0 40.9-33.1 74-74 74H74c-40.9 0-74-33.1-74-74 0-24.2 11.9-47 31.8-60.8l269.8-187.4.1-.1 45.8-32c12.8-9 20.5-23.7 20.5-39.3v-6.3c0-26.5-21.5-48-48-48h-1.5c-22 0-41.2 15-46.6 36.4l-.9 3.4c-4.3 17.1-21.7 27.6-38.8 23.3s-27.6-21.7-23.3-38.8l.9-3.4zM320 255 68.3 429.7c-2.7 1.9-4.3 4.9-4.3 8.2 0 5.5 4.5 10 10 10h492c5.5 0 10-4.5 10-10 0-3.3-1.6-6.4-4.3-8.2z" }, null, -1)
  ])))
}
export default { render: render }